.modal-header {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 15px;
}

.modal-body {
  font-size: 14px;
  margin-bottom: 25px;
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
}

.modal-button {
  padding: 10px 20px;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modal-button-yes {
  background-color: #4caf50;
  color: white;
}

.modal-button-no {
  background-color: #f44336;
  color: white;
}

.modal-button:hover {
  opacity: 0.8;
}
