.full-screen-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  text-align: center;
  color: #fff;
  background: linear-gradient(to top, #000, #6645c8);
}

.content-box {
  padding: 100px;
  border-radius: 0x;
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  width: 100%;
  background: transparent;
  text-align: center;
  position: relative; /* Add position:relative to allow absolute positioning within */
}

.content-box h1 {
  font-size: 3em;
  color: #686da7;
  margin: 20px 0;
}

.content-box p {
  font-size: 2em;
  color: #fff;
}

.processing-message {
  font-size: 2em;
  color: #fff;
}

.logo-image {
  max-width: 300px;
  height: auto;
  position: absolute;
  top: 20px;
  left: 100px;
}

/* Countdown Timer Styles */
.countdown-timer {
  margin-top: 20px; /* Adjust the margin to separate the text and timer */
}

.countdown-values {
  display: flex;
  justify-content: center;
  align-items: center;
}

.countdown-value {
  text-align: center;
  margin: 0 20px; /* Adjust the spacing between countdown elements */
}

.countdown-value span {
  font-size: 2em;
  color: #fff;
}

.countdown-value p {
  font-size: 1.2em;
  color: #fff;
}

.loading-spinner {
  border: 6px solid #fff;
  border-top: 6px solid #686da7; /* Adjust the color as needed */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin: 20px auto; /* Adjust margin as needed */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
