:root {
  --background: white;
  --navbar-width: 230px;
  --navbar-width-min: 80px;
  --navbar-dark-primary: #3c4065;
  --navbar-selected-light: #686da7;
  --navbar-dark-secondary: #4d4f84;
  --navbar-light-primary: #f5f6fa;
  --navbar-light-secondary: #8392a5;
}

html,
body {
  margin: 0;
  background: var(--background);
}

#nab-bar-container {
  display: flex; /* Use flex display to create a vertical column */
}

#nav-bar {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: var(--navbar-dark-primary);
  border-radius: 16px;
  color: var(--navbar-light-primary);
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  overflow: hidden;
  user-select: none;

  &.collapsed .nav-toggle-img {
    opacity: 1;
    transition: opacity 0.5s;
  }

  &.collapsed #nav-header {
    width: calc(var(--navbar-width-min) - 16px);
  }

  &.collapsed #nav-content,
  &.collapsed #nav-footer {
    width: var(--navbar-width-min);
  }

  &.collapsed #nav-header #nav-title {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.1s;
  }

  &.collapsed #nav-header #nav-toggle {
    left: calc(50% - 8px);
    transform: translate(-50%);
  }

  &.collapsed #nav-content .nav-button span {
    opacity: 0;
    transition: opacity 0.1s;
  }

  &.collapsed #nav-content .nav-button {
    min-width: calc(100% - 25px);
  }

  &.collapsed #nav-footer #nav-footer-avatar {
    margin-left: 0;
    left: 50%;
    transform: translate(-50%);
  }

  &.collapsed #nav-footer #nav-footer-titlebox,
  &.collapsed #nav-footer label[for="nav-footer-toggle"] {
    opacity: 0;
    transition: opacity 0.1s;
    pointer-events: none;
  }
}

#nav-bar.sticky {
  position: fixed;
}

/* Add styles for non-sticky state */
#nav-bar.non-sticky {
  position: absolute;
}

#nav-bar hr {
  margin: 0 auto;
  position: relative;
  width: calc(100% - 15px);
  border: none;
  border-top: solid 1px var(--navbar-dark-secondary);
}

#nav-header hr {
  position: absolute;
  bottom: 0;
}

#nav-bar a {
  color: inherit;
  text-decoration: inherit;
}

#nav-header {
  position: relative;
  left: 16px;
  width: calc(var(--navbar-width) - 16px);
  min-height: 80px;
  background: var(--navbar-dark-primary);
  border-radius: 16px 16px 16px 0;
  z-index: 2;
  display: flex;
  align-items: center;
  transition: width 0.2s;
}

#nav-title {
  font-size: 1.5rem;
  transition: opacity 0.5s;
}

#nav-toggle {
  position: absolute;
  right: 0;
  width: 3rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.nav-toggle-img {
  opacity: 0;
  text-align: center;
}

#nav-content {
  margin: -16px 0;
  padding: 16px 0;
  position: relative;
  flex: 1;
  width: var(--navbar-width);
  background: var(--navbar-dark-primary);
  box-shadow: 0 0 0 16px var(--navbar-dark-primary);
  direction: rtl;
  overflow-x: hidden;
  transition: width 0.2s;
  display: flex;
  flex-direction: column;
}

#nav-content::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

#nav-content::-webkit-scrollbar-thumb {
  border-radius: 99px;
  background-color: aliceblue;
}

#nav-content::-webkit-scrollbar-button {
  height: 16px;
}

#nav-content-highlight {
  position: absolute;
  left: 16px;
  top: -86px;
  width: calc(100% - 16px);
  height: 70px;
  background: var(--background);
  background-attachment: fixed;
  border-radius: 16px 0 0 16px;
  transition: top 0.2s;
}
#nav-content-highlight:before,
#nav-content-highlight:after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 100%;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  box-shadow: 16px 16px var(--background);
}
#nav-content-highlight:after {
  top: 100%;
  box-shadow: 16px -16px var(--background);
}

.nav-button {
  position: relative;
  margin-left: 25px;
  height: 70px;
  display: flex;
  align-items: center;
  color: var(--navbar-light-secondary);
  direction: ltr;
  cursor: pointer;
  z-index: 1;
  transition: color 0.2s;
}

.nav-button span {
  transition: opacity 1s;
}

.nav-button:nth-of-type(1):hover span {
  color: var(--navbar-selected-light);
}
.nav-button:nth-of-type(1):hover ~ #nav-content-highlight {
  top: 16px;
}
.nav-button:nth-of-type(2):hover span {
  color: var(--navbar-selected-light);
}
.nav-button:nth-of-type(2):hover ~ #nav-content-highlight {
  top: 86px;
}
.nav-button:nth-of-type(3):hover span {
  color: var(--navbar-selected-light);
}
.nav-button:nth-of-type(3):hover ~ #nav-content-highlight {
  top: 156px;
}
.nav-button:nth-of-type(4):hover span {
  color: var(--navbar-selected-light);
}
.nav-button:nth-of-type(4):hover ~ #nav-content-highlight {
  top: 226px;
}
.nav-button:nth-of-type(5):hover span {
  color: var(--navbar-selected-light);
}
.nav-button:nth-of-type(5):hover ~ #nav-content-highlight {
  top: 296px;
}

#nav-footer {
  position: relative;
  width: var(--navbar-width);
  height: 54px;
  background: var(--navbar-dark-secondary);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  z-index: 2;
  transition: width 0.2s, height 0.2s;
}

#nav-footer.collapsed {
  height: 30%;
  min-height: 54px;
}

#nav-footer-heading {
  position: relative;
  width: 100%;
  height: 54px;
  display: flex;
  align-items: center;
}

#nav-footer-avatar {
  position: relative;
  margin: 11px 0 11px 16px;
  left: 0;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
  transform: translate(0);
  transition: 0.2s;
}
#nav-footer-avatar img {
  height: 100%;
}

#nav-footer-titlebox {
  position: relative;
  margin-left: 16px;
  width: 10px;
  display: flex;
  flex-direction: column;
  transition: opacity 1s;
}

#nav-footer-subtitle {
  color: var(--navbar-light-secondary);
  font-size: 0.6rem;
}

label[for="nav-footer-toggle"] {
  position: absolute;
  right: 0;
  width: 3rem;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: transform 0.2s, opacity 0.2s;
}

#nav-footer-content {
  margin: 0 16px 16px 16px;
  border-top: solid 1px var(--navbar-light-secondary);
  padding: 16px 0;
  color: var(--navbar-light-secondary);
  font-size: 0.8rem;
  overflow: hidden;
}

#nav-footer-content::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

#nav-footer-content::-webkit-scrollbar-thumb {
  border-radius: 99px;
  background-color: aliceblue;
}

.arrow {
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-135deg);
  transition: transform 0.3s, opacity 0.3s;
}

#nav-footer.collapsed .up {
  transform: rotate(45deg);
}

@media screen and (max-width: 639px) {
  :root {
    /* Define any specific mobile variables here */
    --navbar-height-mobile: 60px; /* Example height for mobile navbar */
    --navbar-width-mobile: 2px;
  }

  #nav-bar {
    position: relative;
    flex-direction: row;
    justify-content: center; /* Horizontally center the content */
    align-items: center;
    height: var(--navbar-height-mobile);
    width: 100%;
  }

  #nav-title {
    display: none;
    pointer-events: none;
  }

  #nav-header {
    /* Use flexbox to center-align the children */
    position: relative;
    display: flex;
    left: 6px;
    justify-content: center; /* Horizontally center the content */
    align-items: center; /* Vertically center the content */
    width: auto;
    height: 100%;
    min-height: var(--navbar-height-mobile);
  }

  .nav-toggle-img {
    opacity: 1;
    text-align: center;
  }

  .nav-button:hover ~ #nav-content-highlight {
    /* Disable hover effect that changes position of nav-content-highlight */
    display: none;
  }

  .nav-button span {
    display: none;
  }

  .nav-button {
    position: relative;
    margin-left: 20px;
    height: 70px;
    display: flex;
    align-items: center;
  }

  #nav-content {
    margin: -16px 0;
    padding: 16px 0;
    position: relative;
    height: fit-content;
    background: var(--navbar-dark-primary);
    box-shadow: 0 0 0 16px var(--navbar-dark-primary);
    direction: ltr;
    overflow-x: auto;
    transition: width 0.2s;
    display: flex;
    max-width: 80%;
    flex-direction: row;
    justify-content: start; /* Horizontally center the content */
    align-items: center;
  }

  #nav-content-highlight {
    display: none;
  }

  #nav-footer {
    width: fit-content;
    display: flex;
    justify-content: center;
    margin-right: 8px;
    margin-left: 20px;
    height: 44px;
    border-radius: 10px;
  }

  #nav-footer-content {
    color: white;
    border-top: none;
  }
}

@media screen and (max-width: 400px) {
  #nav-footer-content {
    color: white;
    font-size: 12px;
    border-top: none;
  }
}
